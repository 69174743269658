/* DynamicComponentLoader.css */
.page-container {
    margin: 0 auto;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .login-button {
    font-size: 16px; /* Default font size */
    padding: 8px 15px; /* Adjust padding for the button */
    width: auto; /* Allow width to adjust based on content */
    height: 36px; /* Adjust height to match button */
    background-color: #2471a3; /* Adjust background color for the button */
    color: #fff; /* Adjust text color for the button */
    border-radius: 10px; /* Adjust border radius for the button */
    cursor: pointer; /* Add cursor pointer */
    margin-left: 0px;
  }

  /* library.css */
  .login-form {
    color: #e9cdec;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4a1e52;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .login-form h2 {
    margin-bottom: 20px;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .login-form input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    text-align: center;
    justify-content: center;
  }

  .button-group button:last-child {
    margin-left: 10px;
  }
  
  