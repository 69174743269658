.search-table td {
    border: 1px solid #000;
    width: 200px;
    margin: 5px; /* Add a small margin between cells */
    background-color: #bc76c5;
    border-radius: 10px; /* Add rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    justify-content: center;
    height: 100%; /* Ensure this container has a defined height */
  
}

.search-table td:hover {
  background-color: #a361a4; /* Darkened background color on hover */
}

.search-table img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    padding-top: 5px;
}

.add-button {
  display: block;
  margin-top: 10px;
}

.search-table p {
    text-align: center;
    margin: 5px 0;
}

/* Desktop/Tablet Styles */
@media screen and (min-width: 768px) {
  .search-table tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Align items to the left */
  }
  .search-table td {
    width: calc(100%); /* Set width for cells to fit 6 per row with space between */
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 10px; /* Add space between rows */
  }
}

/* Mobile Styles */
@media screen and (max-width: 767px) {
  .search-table tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .search-table td {
    width: calc(100%); /* Set width for cells to fit 6 per row with space between */
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 10px; /* Add space between rows */
  }
}

.search-item {
    display: flex;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  
  .search-item-left {
    display: flex; /* Use flexbox for centering */
    flex-direction: column;
    align-items: center; /* Center vertically */
    text-align: center;
    justify-content: center;
    padding-right: 5px;
    flex-shrink: 0; /* Ensure the image doesn't shrink */
  }
  
  .search-item-left img {
    width: 100px; /* Set a fixed width for the image */
    height: auto; /* Maintain aspect ratio */
  }
  
  
  .search-item-right {
    text-align: left; /* Align text to the left for better readability */
    padding-left: 5px;
    flex-grow: 1; /* Allow the right side to grow and take available space */
  }
  
  .search-item-right div {
    border-bottom: 1px solid #712a80;; /* Add a line to separate items */
    padding: 5px 0; /* Add padding for spacing */
  }
  
  .search-item-right div:last-child {
    border-bottom: none; /* Remove the border from the last item */
  }
  

  .green-background {
    background-color: green !important;
  }
  
  .book-description {
    height: 160px; /* Set the height to the desired value */
    overflow-y: auto; /* Enable vertical scrollbar if content overflows */
    padding: 10px; /* Optional: Add padding for better appearance */
}
