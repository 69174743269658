.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .column {
    margin: 20px;
    width: 100%;
  }
  
  .column h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .column p {
    margin: 5px 0;
  }
  
  .canvas-container {
    max-width: 100%;
    max-height: 400px;
  }
  
  @media (min-width: 768px) {
    .container {
      flex-wrap: nowrap;
      text-align: center;
    }
  
    .column {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center; /* Align content centrally on larger screens */
      text-align: center;
    }
  
    .column h1 {
      text-align: center; /* Center-align heading */
    }
  }
  