
.app {
  background-color: #712a80;
}

/* Add these styles for the button */
button {
  padding: 8px 16px;
  cursor: pointer;
  background-color: #2471a3; /* Green background color */
  color: white; /* White text color */
  border: none; /* Remove borders */
  border-radius: 4px; /* Rounded corners */
}

.red-button {
  background-color: red;
  color: white; /* Optionally, set the text color */
  /* Add any other styles you want */
}

.custom-table {
  width: calc(100% - 20px); /* Adjusted width to accommodate margins */
  border-collapse: collapse;
  padding-top: 20px;
  margin: 0 auto; /* Center the table horizontally */
  background-color: #712a80;
}

.lib-page {
  background-color: #712a80;
}

.custom-table td {
  border: 1px solid #000;
  width: 200px;
  margin: 5px; /* Add a small margin between cells */
  background-color: #bc76c5;
  border-radius: 10px; /* Add rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.custom-table td:hover {
  background-color: #a361a4; /* Darkened background color on hover */
}

.custom-table img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  padding-top: 5px;
}

.custom-table p {
  text-align: center;
  margin: 5px 0;
}

/* Desktop/Tablet Styles */
@media screen and (min-width: 768px) {
  .custom-table tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Align items to the left */
  }
  .custom-table td {
    width: calc(16.666% - 10px); /* Set width for cells to fit 6 per row with space between */
    margin-bottom: 10px; /* Add space between rows */
  }
  .custom-table tr:nth-last-child(-n+6) td:last-child {
    flex-grow: 0; /* Allow the last cell in the last 6 rows to grow to fill the row */
  }
}

/* Mobile Styles */
@media screen and (max-width: 767px) {
  .custom-table tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .custom-table td {
    width: calc(50% - 10px);
    margin-bottom: 10px;
  }
}

/* Modal styles */
.book-details-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto; /* Allow vertical scrolling */
}

.modal-content {
  background-color: #bc76c5;
  padding: 20px;
  border-radius: 8px;
  max-width: 750px;
  width: 100%;
  max-height: 80%; /* Limit the height of the modal */
  overflow-y: auto; /* Allow content scrolling within the modal */
  display: flex;
  flex-direction: column;
  text-align: center;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.book-description-container {
  display: flex;
  align-items: center;
}

.book-thumbnail {

  max-width: 150px; /* Adjust the width as needed */
}

.book-details ,
.book-description {
  margin-top: 10px;
}

/* Default styling */
.search-box,
.sort-by {
  font-size: 16px; /* Default font size */
  background-color: #f0f0f0; /* Default background color */
  border-radius: 10px; /* Default border radius */
  padding: 8px; /* Default padding */
  margin-bottom: 10px; /* Add margin between stacked elements */
  margin-left: 0px;
  margin-right: 0px;
}

.sort-by-2 {
  font-size: 16px; /* Default font size */
  background-color: #f0f0f0; /* Default background color */
  border-radius: 10px; /* Default border radius */
  padding: 8px; /* Default padding */
  margin-bottom: 10px; /* Add margin between stacked elements */
  margin-left: 5px;
  margin-right: 0px;

}

.select-container {
  display: inline-block;
  text-align: center;
  margin: 0 0px; /* Adjust margin as needed */
}

.select-label {
  display: block;
  font-size: 16px; /* Default font size */
  color: #000000; /* Default color */
  margin-bottom: 2px;
}

.select-box-modal {
  font-size: 16px; /* Default font size */
  background-color: #f0f0f0; /* Default background color */
  border-radius: 10px; /* Default border radius */
  padding: 8px; /* Default padding */
  margin-bottom: 10px; /* Add margin between stacked elements */
  margin-left: 3px;
  margin-right: 3px;
}

.search-button {
  font-size: 16px; /* Default font size */
  padding: 8px 15px; /* Adjust padding for the button */
  width: auto; /* Allow width to adjust based on content */
  height: 36px; /* Adjust height to match button */
  background-color: #2471a3; /* Adjust background color for the button */
  color: #fff; /* Adjust text color for the button */
  border-radius: 10px; /* Adjust border radius for the button */
  cursor: pointer; /* Add cursor pointer */
  margin-left: 10px;
}

.search-button-3 {
  background: none;
  border: 2px solid transparent;
  border-radius: 10px;
  color: white;
  padding: 0.5rem 1rem;
  margin-left: 0rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, border-color 0.3s;
  margin-left: 5px;
}

.scan-button {
  font-size: 16px; /* Default font size */
  padding: 8px 15px; /* Adjust padding for the button */
  width: auto; /* Allow width to adjust based on content */
  height: 36px; /* Adjust height to match button */
  background-color: #4024a3; /* Adjust background color for the button */
  color: #fff; /* Adjust text color for the button */
  border-radius: 10px; /* Adjust border radius for the button */
  cursor: pointer; /* Add cursor pointer */
  margin-left: 10px;
}

.search-button-2 {
  font-size: 16px; /* Default font size */
  padding: 8px 15px; /* Adjust padding for the button */
  width: auto; /* Allow width to adjust based on content */
  height: 36px; /* Adjust height to match button */
  background-color: #2471a3; /* Adjust background color for the button */
  color: #fff; /* Adjust text color for the button */
  border: none; /* Remove border */
  border-radius: 10px; /* Adjust border radius for the button */
  cursor: pointer; /* Add cursor pointer */
}

.edit-modal-buttons button:first {
  margin-left: 0;
}

.label {
  font-size: 22px; /* Default font size */
  color: #000000; /* Default color */
  margin-right: 5px; /* Default margin */
}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .search-box,
  .sort-by,
  .select-box-modal {
    width: calc(50% - 5px); /* Adjust width for 2 elements in a row with a 10px gutter */
    display: inline-block; /* Display elements in a row */
    margin-right: 0px; /* Add margin between elements */
    margin-bottom: 10px; /* Add margin between stacked elements */
  }

  .search-button {
    height: 36px; /* Adjust height to match button */
  }
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-group label {
  width: 150px; /* Adjust the width as needed */
  text-align: right;
  margin-right: 10px;
}

.input-group input {
  flex: 1;
  width: calc(100% - 160px); /* Adjust according to label width */
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  background-color: #60176e;
  border: none;
  color: #fff;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
}

.pagination button.active {
  background-color: #bc76c5;
  color: #fff;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.loading-spinner {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
}

.special-book-item {
  outline: 2px solid gold;
}

.delete-button-book-modal {
  font-size: 16px; /* Default font size */
  padding: 8px 15px; /* Adjust padding for the button */
  width: auto; /* Allow width to adjust based on content */
  height: 36px; /* Adjust height to match button */
  background-color: crimson; /* Adjust background color for the button */
  color: #fff; /* Adjust text color for the button */
  border-radius: 10px; /* Adjust border radius for the button */
  cursor: pointer; /* Add cursor pointer */
  margin-left: 10px;
}

.black {
  color: black;
}

.model-h2 {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  gap: 10px; /* Adjust the spacing between elements as needed */
  justify-content: center;
}
