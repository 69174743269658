.add-button {
  font-size: 16px; /* Default font size */
  padding: 8px 15px; /* Adjust padding for the button */
  width: auto; /* Allow width to adjust based on content */
  height: 36px; /* Adjust height to match button */
  background-color: #2471a3; /* Adjust background color for the button */
  color: #fff; /* Adjust text color for the button */
  border-radius: 10px; /* Adjust border radius for the button */
  cursor: pointer; /* Add cursor pointer */
  margin-left: 15px;
  text-align: center;
}

.delete-button {
  font-size: 16px; /* Default font size */
  padding: 8px 15px; /* Adjust padding for the button */
  width: auto; /* Allow width to adjust based on content */
  height: 36px; /* Adjust height to match button */
  background-color: crimson; /* Adjust background color for the button */
  color: #000000; /* Adjust text color for the button */
  border-radius: 10px; /* Adjust border radius for the button */
  cursor: pointer; /* Add cursor pointer */
  margin-bottom: 15px;
  text-align: center;
}

.name {
  font-size: 16px; /* Default font size */
}

.modal-button-add {
  font-size: 16px; /* Default font size */
  padding: 8px 15px; /* Adjust padding for the button */
  width: auto; /* Allow width to adjust based on content */
  height: 36px; /* Adjust height to match button */
  background-color: #2471a3; /* Adjust background color for the button */
  color: #fff; /* Adjust text color for the button */
  border-radius: 10px; /* Adjust border radius for the button */
  cursor: pointer; /* Add cursor pointer */
  margin-bottom: 15px;
  text-align: center;
  margin-left: 0px;
}

.search-box-add {
  font-size: 16px; /* Default font size */
  background-color: #f0f0f0; /* Default background color */
  border-radius: 10px; /* Default border radius */
  padding: 8px; /* Default padding */
  margin-bottom: 10px;
}


/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .search-box-add {
    width: calc(100% - 10px); /* Adjust width for 2 elements in a row with a 10px gutter */
    display: inline-block; /* Display elements in a row */
    margin-right: 15px; /* Add margin between elements */
    margin-bottom: 10px; /* Add margin between stacked elements */
    justify-content: center;
  }

  .search-button {
    height: 36px; /* Adjust height to match button */
  }

  .margin_45_left_right {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.book-item-collection {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.book-thumbnail-collection {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
}

.book-title-collection {
  flex: 1; /* Allow the title to expand and fill the remaining space */
  text-align: center; /* Center the text horizontally */
}

.delete-button {
  margin-left: auto;
  background-color: crimson;
}

.books-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: auto;
}

.margin_45_left_right {
  margin-left: 45px;
  margin-right: 45px;
}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .margin_45_left_right {
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;
  }
}
