*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

#app {
  height: 100%;
}

body {
  margin: 0;
  line-height: 1.5;
  background-color: #fff;
}

.main {
  font-family: monospace;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.scan {
  margin: 15px auto;
  text-align: center;
}

.scanCanvas {
  -webkit-box-shadow:inset 0 0 0 1px #e6e6e6;
  -moz-box-shadow:inset 0 0 0 1px #e6e6e6;
  box-shadow:inset 0 0 0 1px #e6e6e6;
  border-radius: 6px;
}

.scanBtn {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myHref {
  color: white;
  background-color: #2c1f9e;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.myHref:hover {
  background-color: #2c1f9e;
  color: white;
  outline: none;
  text-decoration: none;
}

.myHref:active {
  background-color: #3223b3;
  text-decoration: none;
  outline: none;
}

.resultModal {
  position: absolute;
  width: 320px;
  transform: translateY(-100%);
  height: 520px;
  background-color: white;
  text-align: center;
}

.result {
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  padding: 20px 20px;
  white-space:pre-wrap;
  background-color: #e6e6e6;
  border-radius: 6px;
}

.ok_btn {
  width: 100px;
  color: #f6f6f6;
  margin: 10px 0;
  outline: none;
  font-family: monospace;
  font-size: 15px;
}

.select-box {
  background-color: #f0f0f0; /* Change to your desired color */
  font-size: 16px; /* Change to your desired font size */
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px; /* Adjust spacing as needed */
  margin-bottom: 10px;
}

.select-box-modal {
  background-color: #f0f0f0; /* Change to your desired color */
  font-size: 16px; /* Change to your desired font size */
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px; /* Adjust spacing as needed */
  margin-bottom: 10px;
  text-align: center;
}

.switch-button{
  margin-top: 20px;
}

.other-button {
  font-size: 16px; /* Default font size */
  padding: 8px 15px; /* Adjust padding for the button */
  width: auto; /* Allow width to adjust based on content */
  height: 36px; /* Adjust height to match button */
  background-color: #2471a3; /* Adjust background color for the button */
  color: #fff; /* Adjust text color for the button */
  border-radius: 10px; /* Adjust border radius for the button */
  cursor: pointer; /* Add cursor pointer */
  margin-left: 0px;
}