/* DynamicComponentLoader.css */

.page-container {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #712a80;
  font-family: Product, sans-serif;
  font-weight: 200;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

header {
  text-align: center; /* Center align header content */
  background-color: #4a1e52;
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items horizontally */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  margin: 0;
  color: #e9cdec;
  margin-bottom: 5px; /* Add some space below h1 */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-container h1 {
  margin: 0;
  font-size: 1.5rem;
}

.nav-button, .auth-button, .profile-button, .logout-button, .search-button, 
.scan-button, .delete-button-book-modal, .delete-button, .add-button,
.modal-button-add, .login-button, .other-button {
  background: none;
  border: 2px solid transparent;
  border-radius: 10px;
  color: white;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, border-color 0.3s;
}

.nav-button, .auth-button, .profile-button, .logout-button, .profile-button {
  margin-top: 10px;
  text-align: left; /* Align buttons to the left */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-button:hover, .auth-button:hover, .profile-button:hover, .logout-button:hover, 
.search-button:hover, .scan-button:hover, .delete-button-book-modal:hover,
.delete-button:hover, .add-button:hover, .modal-button-add:hover,
.login-button:hover, .other-button:hover, .sidebar-toggle-button:hover,
.search-button-3:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: white;
}

.nav-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.profile-container {
  display: flex;
  align-items: center;
}

.navigation-buttons {
  display: flex;
  flex-direction: row; /* Align buttons horizontally */
  justify-content: center; /* Center align buttons horizontally */
  margin-top: 5px; /* Add margin to separate from h1 */
}

.navigation-buttons button {
  margin-right: 5px; /* Add margin between buttons */
}

.navigation-buttons button:last-child {
  margin-right: 0; /* Remove margin from last button */
}

button {
  background-color: #2471a3;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #2c3e50;
}

/* Add styles for the username paragraph */
.navigation-buttons p {
  background-color: #2471a3;
  color: #ffffff;
  margin-left: 10px;
}

@media only screen and (max-width: 600px) {
  header {
    flex-direction: column;
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  h1 {
    margin-bottom: 10px;
  }
  .navigation-buttons {
    display: inline-block; /* Ensure buttons stay in a single row */
    white-space: nowrap; /* Prevent buttons from wrapping */
  }

  /* Add margin to the first button */
  .navigation-buttons {
    padding-left: 20px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem;
  background-color: #4a1e52;
  height: 70px; /* Ensure the header keeps its size */
  z-index: 3; /* Ensure header is above sidebar */
}

.title-container {
  flex-grow: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar-toggle-button {
  position: absolute;
  left: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 4; /* Ensure the button is above the sidebar */
  color: white;
  border: 2px solid transparent;
}

.sidebar {
  position: fixed;
  top: 70px; /* Adjust top to be below the header */
  left: 0;
  height: calc(100% - 70px); /* Adjust height to account for the header */
  width: 250px;
  background-color: #4a1e52;
  padding: 1rem;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 3; /* Sidebar below header and button */
}

.sidebar-overlay {
  display: none; /* Initially hidden */
  position: fixed;
  top: 70px; /* Adjust top to be below the header */
  left: 0;
  width: 100%;
  height: calc(100% - 70px); /* Adjust height to account for the header */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 2; /* Behind the sidebar and button */
}

.sidebar-overlay.open {
  display: block; /* Display when the sidebar is open */
}


.sidebar.open {
  transform: translateX(0);
}

.nav-button i,
.profile-button i,
.logout-button i,
.auth-button i {
  margin-right: 0.5rem;
}